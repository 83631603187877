import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AppLayout from "../layout/appLayout";
import {
  loadCuisines,
  loadInterests,
  loadLanguages,
  loadReligions,
  loadPersonalities,
  retrieveProfilePictures,
  retrieveUser,
} from "../../lib/backendClient";
import { mapEnumToName, mapStatusEnumToName } from "../../lib/utils";

export default function Profile() {
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false);

  const location = useLocation();
  const { userId } = location.state || {};

  const edit = () => {
    navigate("/edit"); // Navigate to the "About" page when button is clicked
  };

  const [profile, setProfile] = useState();
  const [interestOptions, setInterestOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [personalityOptions, setPersonalityOptions] = useState([]);
  const [religionOptions, setReligionOptions] = useState([]);
  const [cuisineOptions, setCuisineOptions] = useState([]);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        
        const [
          interestOptionData,
          languageOptionData,
          religionOptionData, 
          cuisineOptionData,
          personalityOptionData,
          userData
        ] = await Promise.all([
          loadInterests(),
          loadLanguages(),
          loadReligions(),
          loadCuisines(),
          loadPersonalities(),
          retrieveUser(userId)
        ]);

        // Set all states
        setInterestOptions(interestOptionData);
        setLanguageOptions(languageOptionData);
        setReligionOptions(religionOptionData);
        setCuisineOptions(cuisineOptionData);
        setPersonalityOptions(personalityOptionData);
        setProfile(userData);
      } catch (err) {
        // Handle any errors that occur during data fetching
        console.error(err);
      } finally {
        // Ensure loading state is set to false
        setLoaded(true);
      }
    };

    fetchAllData();
  }, [userId]);


  return (
    <AppLayout
      title={"Profil"}
      isNavigationBackShown={userId}
      isSettingsButtonShown={!userId}
      settingsIcon={"bi-gear"}
      settingsAction={edit}
    >
      {!loaded && (
        <div
          className="spinner-grow"
          style={{ color: "var(--p-purple)" }}
          role="status"
        />
      )}
      {loaded && (
        <>
          <div className="row g-2 mb-4">
            <div className="col-12">
              <div id="carouselExampleIndicators" className="carousel slide">
                <div className="carousel-indicators">
                  {profile?.profile_pictures?.map((_, index) => (
                    <button
                      key={`carousel_${index}`}
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to={`${index}`}
                      className={index === 0 ? "active" : ""}
                      aria-current={index === 0}
                      aria-label={`Slide ${index + 1}`}
                    />
                  ))}
                </div>
                <div className="carousel-inner">
                  {profile?.profile_pictures?.map((picture, index) => (
                    <div
                      className={`carousel-item ${!index ? "active" : ""}`}
                      key={`carousel_image_${index}`}
                    >
                      <img
                        src={picture.image}
                        className="d-block w-100"
                        alt="hier hätten wir ein Bild gesehen, wenn es denn angezeigt werden würde 😔"
                      />{" "}
                    </div>
                  ))}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
          <div className="row profile-group">
            <div className="col-12">
              <div className="profile-name">{profile?.first_name}</div>
              <div className="profile-quick-information">
                <div className="quick-information-element">
                  <i className={`bi bi-rulers me-2`} />
                  {profile?.height}cm
                </div>
                <div className="quick-information-element">
                  <i className={`bi bi-handbag me-2`} />
                  {profile?.profession}
                </div>
                <div className="quick-information-element">
                  <i className={`bi bi-house-heart me-2`} />
                  wohnt in {profile?.location_complete}
                </div>
                {profile?.marital_status !== "secret" && (
                  <div className="quick-information-element">
                    <i className={`bi bi-gem me-2`} />
                    {mapStatusEnumToName(profile?.marital_status)}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row profile-group questions">
            <div className="col-12">
              <div className="profile-group-title">
                Erzähle über einen besonderen Moment in den letzten 5 Jahren.
              </div>
              <div className="profile-group-content">{profile?.answer1}</div>
              <div className="profile-group-title">
                Was ist dein Lieblingsreiseziel?
              </div>
              <div className="profile-group-content">{profile?.answer2}</div>
              <div className="profile-group-title">
                Wie sieht dein typisches Wochenende aus?
              </div>
              <div className="profile-group-content">{profile?.answer3}</div>
            </div>
          </div>
          <div className="row profile-group">
            <div className="col-12">
              <div className="profile-group-title">Interessen</div>
              <div className="profile-group-content">
                {profile?.interests?.map((interest, index) => {
                  const interestData = interestOptions?.find(
                    (io) => io.id === interest,
                  );

                  return (
                    <div
                      className="information-element"
                      key={`interest_${index}`}
                    >
                      {interestData?.name} {interestData?.symbol}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="row profile-group">
            <div className="col-12">
              <div className="profile-group-title">Sprachen</div>
              <div className="profile-group-content">
                {profile?.languages?.map((language, index) => {
                  const languageData = languageOptions?.find(
                    (io) => io.id === language,
                  );
                  return (
                    <div
                      className="information-element"
                      key={`language_${index}`}
                    >
                      {languageData?.symbol} {languageData?.name}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="row profile-group">
            <div className="col-12">
              <div className="profile-group-title">Religion</div>
              <div className="profile-group-content">
                {
                  <div className="information-element">
                    {
                      religionOptions?.find((io) => io.id === profile?.religion)
                        ?.name
                    }{" "}
                    {
                      religionOptions?.find((io) => io.id === profile?.religion)
                        ?.symbol
                    }
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="row profile-group">
            <div className="col-12">
              <div className="profile-group-title">Basics</div>
              <div className="profile-group-content">
                <div className="information-element">
                  🎓 {profile?.education}
                </div>
                {profile?.kids !== "secret" && (
                  <div className="information-element">
                    👶🏾 {mapEnumToName(profile?.kids)}
                  </div>
                )}
                {profile?.kids_wish !== "secret" && (
                  <div className="information-element">
                    🤰🏽 {mapEnumToName(profile?.kids_wish)}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row profile-group">
            <div className="col-12">
              <div className="profile-group-title">Persönlichkeitsmerkmale</div>
              <div className="profile-group-content">
                {profile?.personality_traits?.map((personality, index) => {
                  const personalityData = personalityOptions?.find(
                    (io) => io.id === personality,
                  );
                  return (
                    <div
                      className="information-element"
                      key={`personality_trait_${index}`}
                    >
                      {personalityData?.name}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="row profile-group">
            <div className="col-12">
              <div className="profile-group-title">Lifestyle</div>
              <div className="profile-group-content">
                {profile?.smoking !== "secret" && (
                  <div className="information-element">
                    🚬 {mapEnumToName(profile?.smoking)}
                  </div>
                )}
                {profile?.alcohol !== "secret" && (
                  <div className="information-element">
                    🥃 {mapEnumToName(profile?.alcohol)}
                  </div>
                )}
                {profile?.cuisines?.map((cuisine, index) => {
                  const cuisineData = cuisineOptions?.find(
                    (io) => io.id === cuisine,
                  );
                  return (
                    <div
                      className="information-element"
                      key={`cuisine_${index}`}
                    >
                      🍽️ {cuisineData?.name}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>{" "}
        </>
      )}
    </AppLayout>
  );
}
