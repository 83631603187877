import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Optional: Add an interceptor to include the access token in requests
api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        await refreshToken(); // Attempt to refresh the token
        const newAccessToken = localStorage.getItem("accessToken");
        axios.defaults.headers.common["Authorization"] =
          `Bearer ${newAccessToken}`;
        return api(originalRequest); // Retry the original request
      } catch (err) {
        console.error("Auto-refresh failed", err);
        // Handle case where refresh token is invalid
      }
    }
    return Promise.reject(error);
  },
);

export const registerMail = async (email, password) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/api/v1/auth/register-email/",
      {
        email: email,
        password: password,
      },
    );
    if (response?.status === 200) {
      return true;
    } else {
      alert("Server überlastet. Bitte versuche es erneut.");
    }
  } catch (e) {
    if (e?.status === 409) {
      alert("E-Mail-Adresse bereits vergeben.");
    } else {
      alert("Daten ungültig.");
    }
  }
  return false;
};

export const verifyMail = async (email, otp) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/api/v1/auth/verify-email/",
      {
        email: email,
        verification_code: otp,
      },
    );
    if (response?.status == 200) {
      return true;
    } else {
      alert("Code ungültig. Bitte überprüfe deine Mail.");
    }
  } catch (error) {
    alert("Code ungültig. Bitte überprüfe deine Mail.");
  }
  return false;
};

export const passwordReset = async (email) => {
  try {
    await axios.post(
      process.env.REACT_APP_API_URL + "/api/v1/auth/password-reset/",
      {
        email: email,
      },
    );
  } catch (error) {
    console.log(error);
  }
  alert("Ein Verifizierungslink wurde an deine E-Mail-Adresse geschickt.");
  return true;
};

export const updatePassword = async (uidb64, token, newPassword) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL +
        `/api/v1/auth/password-reset-confirm/${uidb64}/${token}/`,
      {
        new_password: newPassword,
      },
    );
    if (response?.status == 200) {
      alert("Passwort aktualisiert");
      return true;
    } else {
      alert("Passwort konnte nicht aktualisiert werden!");
    }
  } catch (error) {
    alert("Passwort konnte nicht aktualisiert werden!");
  }
  return false;
};

export const login = async (email, password) => {
  try {
    const response = await api.post("/api/v1/auth/jwt/create/", {
      email: email,
      password: password,
    });
    if (response?.status == 200) {
      const { access, refresh } = response.data;
      localStorage.setItem("accessToken", access);
      localStorage.setItem("refreshToken", refresh);
      return true;
    } else {
      alert("Login ungültig");
    }
  } catch (error) {
    alert("Login ungültig");
  }
  return false;
};

export const logout = async () => {
  const keysToDelete = ["accessToken", "refreshToken"];
  keysToDelete.forEach((key) => {
    if (localStorage.getItem(key) !== null) {
      localStorage.removeItem(key);
    }
  });
  window.location.href = "/";
};

export const refreshToken = async () => {
  try {
    const refresh = localStorage.getItem("refreshToken");
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/api/v1/auth/jwt/refresh/",
      {
        refresh: refresh,
      },
    );

    // Update access token in local storage
    localStorage.setItem("accessToken", response.data.access);

    console.log("Token refreshed");
  } catch (error) {
    if (error.response?.data)
      console.error("Token refresh failed:", error.response?.data);
    window.location.href = "/login";
  }
};

export const autoLogin = async () => {
  try {
    const refresh = localStorage.getItem("refreshToken");
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/api/v1/auth/jwt/refresh/",
      {
        refresh: refresh,
      },
    );
    localStorage.setItem("accessToken", response.data.access);

    return true;
  } catch (error) {
    if (error.response?.data)
      console.error("Token refresh failed:", error.response?.data);
  }
  return false;
};

export const loadReligions = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "/api/v1/profiles/religions",
    );
    if (response?.status == 200) {
      return response?.data;
    }
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const loadInterests = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "/api/v1/profiles/interests",
    );
    if (response?.status == 200) {
      return response?.data;
    }
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const loadPersonalities = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "/api/v1/profiles/personalities",
    );
    if (response?.status == 200) {
      return response?.data;
    }
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const loadLanguages = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "/api/v1/profiles/languages",
    );
    if (response?.status == 200) {
      return response?.data;
    }
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const loadCuisines = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "/api/v1/profiles/cuisines",
    );
    if (response?.status == 200) {
      return response?.data;
    }
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const updateUser = async (payload) => {
  try {
    const response = await api.patch("/api/v1/profiles/users/", {
      ...payload,
    });
    if (response?.status == 200) {
      return true;
    }
  } catch (e) {
    alert(
      "Benutzer konnte nicht aktualisiert werden. Versuche es später erneut.",
    );
  }
  return false;
};

export const retrieveUser = async (userId = "") => {
  try {
    const response = await api.get(
      process.env.REACT_APP_API_URL + `/api/v1/profiles/users/${userId}`,
    );
    if (response?.status == 200) {
      return response?.data;
    }
  } catch (error) {
    console.log(error);
  }
  return {};
};

export const retrieveProfilePictures = async (userId = "") => {
  try {
    const response = await api.get(
      process.env.REACT_APP_API_URL + `/api/v1/profiles/pictures/${userId}`,
    );
    if (response?.status == 200) {
      return response?.data;
    }
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const createPicture = async (image) => {
  if (!image?.data) return true;

  const formData = new FormData();
  formData.append("image", image?.data);

  try {
    const response = await api.post("/api/v1/profiles/pictures/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response?.status == 201) {
      return true;
    }
  } catch (e) {
    console.log("Bild konnte nicht hochgeladen werden.");
  }
  return false;
};

export const updatePicture = async (image) => {
  if (!image?.data) return true;

  const formData = new FormData();
  formData.append("image", image.data);

  try {
    const response = await api.put(
      `/api/v1/profiles/pictures/${image.id}/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    if (response?.status == 200) {
      return true;
    }
  } catch (e) {
    console.log("Bild konnte nicht hochgeladen werden.");
  }
  return false;
};

export const deletePicture = async (image) => {
  if (!image?.id) return true;
  try {
    const response = await api.delete(`/api/v1/profiles/pictures/${image.id}/`);
    if (response?.status == 204) {
      return true;
    }
  } catch (e) {
    console.log("Bild konnte nicht gelöscht werden.");
  }
  return false;
};

export const savePaymentMethod = async (paymentMethodId) => {
  try {
    const response = await api.post(
      "/api/v1/subscriptions/save-payment-method/",
      {
        payment_method_id: paymentMethodId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    if (response?.status == 200) {
      return { success: true, error: [] };
    } else {
      //alert(JSON.stringify(response));
      return { success: false, error: [response?.data?.error] };
    }
  } catch (error) {
    //alert(JSON.stringify(error));
    return { success: false, error: ["Service nicht erreichbar"] };
  }
};

export const retrieveCandidates = async () => {
  try {
    const response = await api.get("/api/v1/matching/");
    if (response?.status == 200) {
      return response?.data;
    }
  } catch (e) {
    if (e?.status === 403) {
      return "unverified"; // naming has to be 'unverified'
    } else {
      console.log(e);
    }
  }
  return [];
};

export const match = async (userId, state) => {
  try {
    const response = await api.post("/api/v1/matching/swipe/", {
      swiped_on: userId,
      swipe_type: state,
    });
    if (response?.status == 200) {
      return response?.data;
    }
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const loadMatches = async () => {
  try {
    const response = await api.get("/api/v1/matching/matches/");
    if (response?.status == 200) {
      return response?.data;
    }
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const deleteMatch = async (matchId) => {
  try {
    const response = await api.delete(`/api/v1/matching/matches/${matchId}/`);
    if (response?.status == 200) {
      return response?.data;
    }
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const loadChats = async () => {
  try {
    const response = await api.get("/api/v1/chat/");
    if (response?.status == 200) {
      return response?.data;
    }
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const loadChatMessages = async (chatId) => {
  try {
    const response = await api.get(`/api/v1/chat/${chatId}/`);
    if (response?.status == 200) {
      return response?.data;
    }
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const uploadVoiceMessage = async (chatId, audioBlob) => {
  try {
    const formData = new FormData();
    formData.append("file", audioBlob, "voice-message.mp4");

    const response = await api.post(`/api/v1/chat/${chatId}/files/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response?.status == 200) {
      return response?.data;
    }
  } catch (error) {
    console.log(error);
  }
  return {};
};

export const approveChat = async (chatId, approval) => {
  try {
    const response = await api.post(`/api/v1/chat/${chatId}/approval/`, {
      is_approved: approval,
    });
    if (response?.status == 200) {
      return true;
    }
  } catch (error) {
    console.log(error);
  }
  return false;
};

export const requestChat = async (userId) => {
  try {
    const response = await api.post(`/api/v1/chat/`, { id: userId });
    if (response?.status == 200) {
      return true;
    }
  } catch (error) {
    if (error?.status == 429)
      alert(
        "Dein Limit von 2 Matchanfragen in 24 Stunden ist aufgebraucht. Bitte probiere es später wieder.",
      );
    console.log(error);
  }
  return false;
};

export const blockUser = async (chatId) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_API_URL + `/api/v1/chat/${chatId}/block/`,
    );
    if (response?.status === 200) {
      return true;
    } else {
      alert("Server überlastet. Bitte versuche es erneut.");
    }
  } catch (e) {
    if (e?.status === 404) {
      alert("Chat wurde nicht gefunden.");
    }
  }
  return false;
};

export const reportUser = async (chatId) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_API_URL + `/api/v1/chat/${chatId}/report/`,
      {
        text: null,
      },
    );
    if (response?.status === 200) {
      return true;
    } else {
      alert("Server überlastet. Bitte versuche es erneut.");
    }
  } catch (e) {
    if (e?.status === 404) {
      alert("Chat wurde nicht gefunden.");
    }
  }
  return false;
};

export const loadVerificationUrl = async () => {
  try {
    const response = await api.post(
      process.env.REACT_APP_API_URL +
        `/api/v1/subscriptions/stripe-verification/`,
    );
    if (response?.status === 200) {
      return response?.data;
    } else {
      alert("Server überlastet. Bitte versuche es erneut.");
    }
  } catch (error) {
    console.log(error);
  }
  return false;
};

export const loadVerificationStatus = async () => {
  try {
    const response = await api.get(
      process.env.REACT_APP_API_URL +
        `/api/v1/subscriptions/stripe-verification/`,
    );
    if (response?.status === 200) {
      return response?.data;
    } else {
      alert("Server überlastet. Bitte versuche es erneut.");
    }
  } catch (error) {
    console.log(error);
  }
  return false;
};

export const loadNotificationStatus = async () => {
  try {
    const response = await api.get(
      process.env.REACT_APP_API_URL + `/api/v1/notifications/`,
    );
    if (response?.status === 200) {
      return response?.data;
    } else {
      alert("Server überlastet. Bitte versuche es erneut.");
    }
  } catch (error) {
    console.log(error);
  }
  return false;
};
