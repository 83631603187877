import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { loadNotificationStatus } from "../../lib/backendClient";

const menu = [
  {
    navigateTo: "/swipe",
    selectedOn: ["/swipe", "/swipeFilter"],
    icon: "bi-house-heart",
    title: "Home",
  },
  {
    navigateTo: "/matches",
    selectedOn: ["/matches"],
    icon: "bi-arrow-through-heart",
    title: "Matches",
  },
  {
    navigateTo: "/chats",
    selectedOn: ["/chats"],
    icon: "bi-chat-dots",
    title: "Chats",
  },
  {
    navigateTo: "/profile",
    selectedOn: ["/profile", "/edit"],
    icon: "bi-person",
    title: "Profil",
  },
];

const Navigation = ({ hasUnseenMatches, hasUnseenChats }) => {
  const location = useLocation();
  return (
    <div className="container menu">
      <div className="row">
        {menu?.map((menuItem, index) => (
          <div
            className="col-3 quick-navigation-item"
            key={`menu_item_${index}`}
          >
            <Link
              to={menuItem.navigateTo}
              style={
                menuItem?.selectedOn?.includes(location?.pathname)
                  ? { color: "var(--p-purple)" }
                  : {}
              }
            >
              <div className="quick-navigation-item-icon">
                <i className={`fs-3 bi ${menuItem.icon}`}></i>
                {menuItem.title === "Matches" && hasUnseenMatches && (
                  <span
                    className="notification-dot"
                    style={{
                      width: "12px",
                      height: "12px",
                      backgroundColor: "var(--p-purple)",
                      borderRadius: "50%",
                      position: "absolute",
                      top: "0",
                      right: "-20px",
                    }}
                  ></span>
                )}
                {/* Display violet dot if there are unseen matches or chats */}
                {menuItem.title === "Chats" && hasUnseenChats && (
                  <span
                    className="notification-dot"
                    style={{
                      width: "12px",
                      height: "12px",
                      backgroundColor: "var(--p-purple)",
                      borderRadius: "50%",
                      position: "absolute",
                      top: "0",
                      right: "-20px",
                    }}
                  ></span>
                )}
              </div>
              <div className="navigation-title">{menuItem.title}</div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default function AppLayout({
  children,
  title = "",
  isNavigationBackShown = false,
  onNavigation = () => {},
  isSettingsButtonShown = false,
  settingsIcon = "bi-gear",
  settingsAction,
  isNavigationMenuShown = true,
}) {
  const navigate = useNavigate();
  const [hasUnseenMatches, setHasUnseenMatches] = useState(false);
  const [hasUnseenChats, setHasUnseenChats] = useState(false);

  // Function to fetch notifications using loadNotificationStatus
  const fetchNotifications = async () => {
    const data = await loadNotificationStatus();
    if (data) {
      setHasUnseenMatches(data.has_unseen_matches);
      setHasUnseenChats(data.has_unseen_chats);
    }
  };

  // Polling for notifications every 15 seconds
  useEffect(() => {
    fetchNotifications(); // Initial fetch
    const interval = setInterval(fetchNotifications, 15000); // 15 seconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  const back = () => {
    onNavigation();
    navigate(-1);
  };

  return (
    <div className="app">
      <div className="container top-element">
        <div className="row controls">
          <div className="col-3">
            {isNavigationBackShown && (
              <i onClick={back} className="bi fs-4 bi-arrow-left" />
            )}
          </div>
          <div className="col-6">
            <div className="action">{title}</div>
          </div>
          <div className="col-3 d-flex justify-content-end">
            {isSettingsButtonShown && (
              <i
                className={`bi fs-4 ${settingsIcon}`}
                onClick={settingsAction}
              ></i>
            )}
          </div>
        </div>
      </div>
      <div className="container content-element">{children}</div>
      {isNavigationMenuShown && (
        <Navigation
          hasUnseenMatches={hasUnseenMatches}
          hasUnseenChats={hasUnseenChats}
        />
      )}
    </div>
  );
}
