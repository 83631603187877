import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppLayout from "../layout/appLayout";
import { loadChats } from "../../lib/backendClient";
import placeholder from "../../static/images/welcome.png"

const toDateString = (isoDate) => {
  const date = new Date(isoDate);

  const formattedDate = new Intl.DateTimeFormat("de-DE", {
    day: "2-digit",
    month: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  }).format(date);

  return formattedDate;
};

export default function Chats() {
  const navigate = useNavigate();
  const [chats, setChats] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    loadChats().then((chatData) => {
      setChats(chatData);
      setLoaded(true);
    });
  }, []);

  const chatView = (chat) => {
    navigate("/chat", { state: { chat: chat } });
  };
  const matchRequests = chats.filter(
    (chat) => chat.requestor && !chat.is_approved,
  );
  const activeChats = chats.filter(
    (chat) => (!chat.requestor || chat.is_approved),
  );

  return (
    <AppLayout title={"Chats"}>
      <div className="row chats-view">
        <div className="col-12">
          {matchRequests.length > 0 && (
            <>
              <div className="chat-category">Matchanfragen</div>
              {matchRequests.map((chat) => (
                <div
                  className={`row chat ${chat.block ? "block" : ""}`}
                  onClick={() => {
                    if (!chat.block) chatView(chat);
                  }}
                  key={chat.id}
                >
                  <div className="col partner-avatar">
                    <div className="chat-partner-avatar">
                      <img
                        src={chat.participants[0]?.profile_picture?.image ?? placeholder}
                        alt=""
                        className="chat-partner avatar"
                      />
                      {chat.unseen_count > 0 && (
                        <div className="chat-notification">
                          {chat.unseen_count}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="chat-partner-name">
                      {chat.participants[0]?.first_name ?? "Paalam-Benutzer"}
                    </div>
                    <div className="chat-last-message">
                      {chat.last_message
                        ? chat.last_message.content
                        : "Neuer Chat"}
                    </div>
                  </div>
                  <div className="col chat-date">
                    <div className="chat-last-message-time">
                      {chat.last_message
                        ? toDateString(chat.last_message.date_added)
                        : toDateString(chat.date_added)}
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
          {matchRequests.length > 0 && activeChats.length > 0 && <hr />}
          {activeChats.length > 0 && (
            <>
              <div className="chat-category">Chats</div>
              {activeChats.map((chat) => (
                <div
                  className={`row chat ${chat.block ? "block" : ""}`}
                  onClick={() => {
                    if (!chat.block) chatView(chat);
                  }}
                  key={chat.id}
                >
                  <div className="col partner-avatar">
                    <div className="chat-partner-avatar">
                      <img
                        src={chat.participants[0]?.profile_picture?.image ?? placeholder}
                        alt=""
                        className="chat-partner avatar"
                      />
                      {chat.unseen_count > 0 && (
                        <div className="chat-notification">
                          {chat.unseen_count}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="chat-partner-name">
                      {chat.participants[0]?.first_name ?? "Paalam-Benutzer"}
                    </div>
                    <div className="chat-last-message">
                      {chat.last_message
                        ? chat.last_message.content
                        : "Neuer Chat"}
                    </div>
                  </div>
                  <div className="col chat-date">
                    <div className="chat-last-message-time">
                      {chat.last_message
                        ? toDateString(chat.last_message.date_added)
                        : toDateString(chat.date_added)}
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
          {loaded && matchRequests.length === 0 && activeChats.length === 0 && (
            <div className="no-chats-message">Du hast keine aktiven Chats.</div>
          )}

          {!loaded && (
            <div
              className="spinner-grow"
              style={{ color: "var(--p-purple)" }}
              role="status"
            />
          )}
        </div>
      </div>
    </AppLayout>
  );
}
