import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getNavigationPath } from "../lib/userRouting";
import {
  autoLogin,
  retrieveUser,
  retrieveProfilePictures,
} from "../lib/backendClient";

export default function Welcome() {
  const [loading, setLoading] = useState(true);
  const [installPrompt, setInstallPrompt] = useState(null);
  const [installed, setInstalled] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [isStandalone, setIsStandalone] = useState(false);
  const navigate = useNavigate();

  const register = () => {
    navigate("/register");
  };

  const login = () => {
    navigate("/login");
  };

  // Handle the installation button click
  const handleInstallClick = () => {
    if (installPrompt) {
      installPrompt.prompt();
      installPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the installation prompt");
        } else {
          console.log("User dismissed the installation prompt");
        }
        setInstallPrompt(null); // Clear the prompt after user interaction
      });
    } else if (isIOS) {
      alert(
        "Um die App zu installieren, tippen Sie auf das 'Teilen' Symbol in Ihrem Browser und wählen Sie 'Zum Home-Bildschirm hinzufügen'.",
      );
    } else {
      alert(
        "Um die App zu installieren, verwenden Sie bitte die Funktion 'Zum Home-Bildschirm hinzufügen' in Ihrem Browser.",
      );
    }
  };

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);

    if (navigator.getInstalledRelatedApps)
      navigator
        .getInstalledRelatedApps()
        .then((res) => setInstalled(res.length > 0));

    // Detect if the device is iOS
    setIsIOS(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);

    // Detect if app is installed as a PWA
    setIsStandalone(window.matchMedia("(display-mode: standalone)").matches);

    // Listen for the beforeinstallprompt event on supported browsers
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault(); // Prevent automatic prompt
      console.log("beforeinstallprompt event fired");
      setInstallPrompt(event); // Save the event for triggering later
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    // Clear the prompt if the app gets installed
    window.addEventListener("appinstalled", () => {
      console.log("App was successfully installed");
      setInstallPrompt(null);
    });

    // Perform auto-login if available
    autoLogin().then((result) => {
      if (result) {
        retrieveUser().then((user) => {
          retrieveProfilePictures().then((profilePictures) => {
            const path = getNavigationPath(user, profilePictures);
            navigate(path, { state: { email: user.email } });
          });
        });
      }
    });

    // Clean up event listeners on unmount
    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt,
      );
    };
  }, [navigate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-[#9610FF]">
        <img
          src={require("../static/images/logo.png")}
          alt="Paalam Logo"
          className="w-32 h-32"
        />
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center welcome">
      <div className="sign-in w-60 m-auto">
        <div className="container">
          <div className="row">
            <div className="col">
              <img
                src={require("../static/images/logo.png")}
                alt="Paalam Logo"
                className="welcome-logo"
              />
              <h1 className="text-center d-none">Paalam</h1>
              <h2>Vom Chat zum Kalyanam</h2>
              <div className="choice-options">
                <button onClick={register} className="btn btn-primary py-2">
                  Registrieren
                </button>
                <button onClick={login} className="btn btn-secondary py-2">
                  Login
                </button>
              </div>
              <div className="choice-options">
                {!isStandalone && (
                  <button
                    onClick={handleInstallClick}
                    className="btn btn-primary"
                    disabled={(!installPrompt && !isIOS) || installed}
                  >
                    App installieren
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
