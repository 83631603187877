// ChatView.js
import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  loadChatMessages,
  uploadVoiceMessage,
  approveChat,
  refreshToken,
  loadChats,
} from "../../lib/backendClient";
import VoiceMessagePlayer from "../../component/VoiceMessagePlayer";
import AudioRecorder from "../../component/AudioRecorder"; // Import the AudioRecorder
import placeholder from "../../static/images/welcome.png"

const toDateString = (isoDate) => {
  const date = new Date(isoDate);
  const cmpDate = new Date(isoDate);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const isToday = cmpDate.setHours(0, 0, 0, 0) === today.getTime();

  if (isToday)
    return new Intl.DateTimeFormat("de-DE", {
      hour: "2-digit",
      minute: "2-digit",
    }).format(date);
  else
    return new Intl.DateTimeFormat("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).format(date);
};

export default function ChatView() {
  const navigate = useNavigate();
  const back = () => navigate(-1);

  const chatOptions = () => {
    navigate("/chatOptions", { state: { chat: chat } });
  };

  const showProfile = async (userId) => {
    if (userId)
      navigate("/profile", { state: { userId: userId } });
  };

  const location = useLocation();
  const [chat, setChat] = useState(location.state?.chat || {});

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [chatSocket, setChatSocket] = useState(null);
  const textareaRef = useRef(null);
  const appRef = useRef(null);

  useEffect(() => {
    loadChatMessages(chat.id).then((chatMessages) => {
      setMessages(chatMessages?.results?.slice().reverse() || []);
    });

    const token = localStorage.getItem("accessToken");
    if (!token || !chat) return;

    const socket = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET}/ws/chat/${chat.key}/?token=${token}`,
    );

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      setMessages((prevMessages) => {
        return [...prevMessages, message];
      });
      // Send acknowledgment back to the server
      socket.send(
        JSON.stringify({
          content: "",
          message_type: "ACK",
          chat_id: chat.id,
        }),
      );

      if (chat.requestor && chat.is_approved === null)
        loadChats().then((chats) => {
          setChat(chats?.find((io) => io.id === chat?.id));
        });
    };

    socket.onclose = (e) => {
      if (e.code === 4001) {
        navigate(0);
      }
    };

    const intervalId = setInterval(refreshToken, 200000);
    setChatSocket(socket);

    return () => {
      socket.close();
      clearInterval(intervalId);
    };
  }, [chat]);

  const scrollToBottom = () => {
    const messageBox = appRef.current;
    if (messageBox) {
      if (messageBox.scrollHeight > window.innerHeight) {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    loadChats().then((chats) => {
      setChat(chats?.find((io) => io.id === chat?.id));
    });
  }, []);

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${Math.min(textarea.scrollHeight, 140)}px`;
    }
  };

  const sendMessage = (isMatchRequest) => {
    if (chatSocket && chatSocket.readyState === WebSocket.OPEN) {
      chatSocket.send(
        JSON.stringify({
          content: newMessage,
          message_type: "text",
          chat_id: chat.id,
        }),
      );
      setNewMessage("");
      adjustHeight();
      if (isMatchRequest) {
        loadChats().then((chats) => {
          setChat(chats?.find((io) => io.id === chat?.id));
        });
      }
    }
  };

  const sendVoiceMail = async (e, isMatchRequest) => {
    const audioUploadResult = await uploadVoiceMessage(chat.id, e);
    if (
      audioUploadResult?.key &&
      chatSocket &&
      chatSocket.readyState === WebSocket.OPEN
    ) {
      chatSocket.send(
        JSON.stringify({
          content: audioUploadResult?.key,
          message_type: "voice",
          chat_id: chat.id,
        }),
      );
      if (isMatchRequest) {
        loadChats().then((chats) => {
          setChat(chats?.find((io) => io.id === chat?.id));
        });
      }
    }
  };

  const [ask, setAsk] = useState(true);

  return (
    <div className="app" ref={appRef}>
      <div className="container top-element">
        <div className="row controls mb-4">
          <div className="col-3">
            <i onClick={back} className="bi fs-4 bi-x" />
          </div>
          <div className="col-6">
            <div
              className="action"
              onClick={() => showProfile(chat.participants[0]?.id)}
            >
              {chat.participants[0]?.first_name ?? "Paalam-Benutzer"}
            </div>
          </div>
          <div className="col-3 d-flex justify-content-end">
            <i onClick={chatOptions} className="bi fs-4 bi-three-dots" />
          </div>
        </div>
      </div>
      <div className="container content-element">
        <div className="row">
          <div className="chat-view">
            <div className="col-12">
              <div className="messages">
                {messages?.map((message, index) => (
                  <div
                    key={index}
                    className={`msg-n msg-n-${
                      chat.participants[0]?.id !== message.sender ? "s" : "r"
                    }`}
                  >
                    <div
                      className={`msg-n-c-${
                        chat.participants[0]?.id !== message.sender ? "s" : "r"
                      }`}
                    >
                      {message.message_type === "text" && (
                        <div className="message-text">{message.content}</div>
                      )}
                      {message.message_type === "voice" && (
                        <VoiceMessagePlayer audioSrc={message.file_url} />
                      )}
                      <div className="message-time">
                        {toDateString(message.date_added)}
                      </div>
                    </div>
                  </div>
                ))}
                {ask && chat.requestor && !chat.is_approved && (
                  <>
                    <div className="match-profiles">
                      <div className="chat-partner-avatar">
                        <img
                          src={chat.participants[0]?.profile_picture?.image ?? placeholder }
                          alt=""
                          onClick={() => showProfile(chat.participants[0]?.id)}
                        />
                      </div>
                    </div>
                    <div className="match-notification">
                      {chat.requestor === chat.participants[0]?.id
                        ? `Du hast eine Matchanfrage von ${chat.participants[0]?.first_name ?? "Paalam-Benutzer"} erhalten!`
                        : `Du hast eine Matchanfrage an ${chat.participants[0]?.first_name ?? "Paalam-Benutzer"} gesendet!`}
                    </div>
                    {chat.requestor === chat.participants[0]?.id && (
                      <div className="match-request-actions">
                        <button
                          className="btn btn-secondary"
                          onClick={async () => {
                            await approveChat(chat.id, true);
                            setAsk(false);
                            loadChats().then((chats) => {
                              setChat(chats?.find((io) => io.id === chat?.id));
                            });
                          }}
                        >
                          <i className="bi bi-heart-fill"></i> Annehmen
                        </button>
                        <button
                          className="btn btn-secondary"
                          onClick={async () => {
                            await approveChat(chat.id, false);
                            setAsk(false);
                          }}
                        >
                          <i className="bi bi-heartbreak"></i> Ablehnen
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container bottom-element">
        <div className="row">
          <div className="chat-input">
            <div className="col-10 chat-textarea">
              <textarea
                ref={textareaRef}
                placeholder={
                  chat.requestor &&
                  chat.is_approved === null &&
                  chat.last_message === null &&
                  chat.requestor !== chat.participants[0]?.id
                    ? (chat.participants[0]?.id ? "Füge deiner Anfrage eine persönliche Nachricht hinzu.": "Benutzer gelöscht")
                    : (chat.participants[0]?.id ? "Nachricht": "Benutzer gelöscht")
                }
                autoComplete="off"
                value={newMessage}
                onChange={(event) => {
                  if (chat.participants[0]?.id){
                    setNewMessage(event.target.value);
                    adjustHeight();
                  }
                }}
                onKeyDown={(event) => {
                  if (chat.participants[0]?.id && event.key === "Enter") {
                    event.preventDefault();
                    setNewMessage((prevMessage) => prevMessage + "\n");
                    adjustHeight();
                  }
                }}
              />
            </div>
            { chat.participants[0]?.id &&
              <div className="col chat-act">
                {newMessage.trim() === "" ? (
                  <AudioRecorder
                    onUpload={(event) =>
                      sendVoiceMail(
                        event,
                        chat.requestor &&
                          chat.is_approved === null &&
                          chat.last_message === null &&
                          chat.requestor !== chat.participants[0]?.id,
                      )
                    }
                    disabled={
                      chat.requestor &&
                      chat.is_approved === null &&
                      (chat.last_message ||
                        chat.requestor === chat.participants[0]?.id)
                    }
                  />
                ) : (
                  <button
                    className="btn btn-send"
                    onClick={() =>
                      sendMessage(
                        chat.requestor &&
                          chat.is_approved === null &&
                          chat.last_message === null &&
                          chat.requestor !== chat.participants[0]?.id,
                      )
                    }
                    disabled={
                      chat.requestor &&
                      chat.is_approved === null &&
                      (chat.last_message ||
                        chat.requestor === chat.participants[0]?.id)
                    }
                  >
                    <i className="fs-5 bi bi-send"></i>
                  </button>
                )}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
