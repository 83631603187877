import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  createPicture,
  updatePicture,
  deletePicture,
  retrieveProfilePictures,
} from "../../lib/backendClient";
import RegisterFormLayout from "../layout/registerFormLayout";

export default function Create_Pictures() {
  const navigate = useNavigate();
  const location = useLocation();

  const [disabled, setDisabled] = useState(true);
  const [images, setImages] = useState(Array(6).fill(null)); // State to store uploaded images

  // File input handler
  const handleImageUpload = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newImages = [...images];
        newImages[index] = { data: file, dataUrl: reader.result }; // Set the image URL
        if (images[index]?.id) newImages[index].id = images[index].id;

        setImages(newImages);

        // Enable "Weiter" button if at least 3 images are uploaded
        if (newImages.filter((img) => img?.dataUrl).length >= 3) {
          setDisabled(false);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  // Remove image handler
  const handleRemoveImage = (index) => {
    const fileInput = document.getElementById(`fileInput${index}`);
    fileInput.value = "";
    const newFileInput = fileInput.cloneNode(true);
    newFileInput.addEventListener("change", (event) =>
      handleImageUpload(index, event),
    );
    fileInput.parentNode.replaceChild(newFileInput, fileInput);

    const newImages = [...images];
    if (images[index]?.id) {
      newImages[index] = { id: images[index]?.id };
    } else {
      newImages[index] = null; // Clear the image
    }
    setImages(newImages);
    // Disable "Weiter" button if less than 3 images remain
    if (newImages.filter((img) => img?.dataUrl).length < 3) {
      setDisabled(true);
    }
  };

  // Handle "Weiter" button click (POST request)
  const checkout = async () => {
    const uploadQueue = images.map(async (image) => {
      if (!image?.id) {
        return await createPicture(image);
      } else if (image?.dataUrl) {
        return await updatePicture(image);
      } else {
        return await deletePicture(image);
      }
    });
    const uploadResults = await Promise.all(uploadQueue);
    const successfulUpload = uploadResults.every((result) => result === true);
    if (successfulUpload)
      navigate("/create_payment", {
        state: { ...location.state },
      });
  };

  useEffect(() => {
    retrieveProfilePictures().then((data) => {
      if (data.length > 0) {
        setImages(
          images.map((_, index) => {
            if (data[index]) {
              return {
                dataUrl: `${data[index].image}`,
                id: data[index].id,
              };
            }
            return null; // Keep null if there's no data for this index
          }),
        );
        setDisabled(false);
      }
    });
  }, []);

  return (
    <RegisterFormLayout
      isNavigationBackShown={true}
      progress={77}
      actionDisabled={disabled}
      action={checkout}
      actionText={"Weiter"}
    >
      <div className="col sign-in w-100 m-auto">
        <img src="" className="mb-4" />
        <h1 className="">Fotos hochladen</h1>
        <div className="task-description">
          Du musst mindestens 3 Fotos hochladen, um dein Profil zu
          vervollständigen. Du kannst sie später ändern.
        </div>
      </div>
      <div className="row g-2">
        {images.map((image, index) => (
          <div key={index} className="col-4 picture-element">
            {image?.dataUrl && (
              <div className="picture-delete">
                <i
                  className="bi bi-x"
                  onClick={() => handleRemoveImage(index)}
                ></i>
              </div>
            )}
            <div
              className="picture"
              style={{
                backgroundImage: image ? `url(${image.dataUrl})` : "",
                backgroundSize: "cover", // Auto-scale and crop the image to fill the element
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              onClick={() =>
                document.getElementById(`fileInput${index}`).click()
              }
            >
              {!image?.dataUrl && <i className="bi bi-plus-lg"></i>}
            </div>
            <input
              type="file"
              accept="image/*"
              id={`fileInput${index}`}
              style={{ display: "none" }}
              onChange={(event) => handleImageUpload(index, event)}
            />
          </div>
        ))}

        <div className="col-12">
          <div className="picture-policies">
            <strong>Richtlinien für das Foto:</strong>
            <ol>
              <li>
                Verwende ein kürzlich aufgenommenes Foto, das dein aktuelles
                Aussehen widerspiegelt.
              </li>
              <li>
                Das Foto sollte klar und gut beleuchtet sein, ohne Unschärfen
                oder Pixelation. Dein Gesicht soll klar erkennbar sein.
              </li>
              <li>Stelle sicher, dass nur du auf dem Foto zu sehen bist.</li>
              <li>
                Trage angemessene und respektvolle Kleidung, die zu einem
                seriösen Profil passt.
              </li>
              <li>
                Verwende einen neutralen Hintergrund, um Ablenkungen zu
                vermeiden und den Fokus auf dich zu richten.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </RegisterFormLayout>
  );
}
